.calendarMain {
  /* width: 100%; */
  background-color: red;
}
.calendarClassName {
  background-color: transparent;
}

.dayClassName {
  background-color: yellow;
}

.monthClassName {
  background-color: blue;
  font-size: 30px;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid #ececec;
}

.react-datepicker__time-container {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}

.react-datepicker {
  border: none;
}

.rmdp-day.rmdp-day-hidden {
  background-color: transparent;
}

.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-day {
  background-color: #ebf1ff;
  color: #2e5dcd;
  margin: 8px;
  border-radius: 100%;
  @media screen and (max-width: 1024px) {
    margin: 5px;
  }
}

.rmdp-selected {
  background-color: black;
  color: white;
}

.rmdp-day.rmdp-selected span:not(.highlighted) {
  background-color: transparent !important;
}

.rmdp-today {
  background-color: #90d7a4 !important;
}

.rmdp-day.rmdp-today span {
  color: #00832d !important;
  background-color: transparent !important;
}

.rmdp-ym .rmdp-day {
  border-radius: 12px;
  margin: 0 10px;
  background-color: transparent;
}

.rmdp-ym .rmdp-today {
  background-color: transparent !important;
}

.rmdp-ym .rmdp-selected {
  background-color: black;
  color: black;
}

.rmdp-border-bottom {
  border-bottom: none !important;
}
