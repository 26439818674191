@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-card .ant-card-meta-title {
  margin-bottom: 0; /* Adjust this as needed */
}

.ant-card-meta-description {
  margin-top: 0; /* Reduce the default margin */
}

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inter";
  /* font-family: "Outfit", sans-serif; */
  /* font-family: "Teachers", sans-serif; */
  /* font-family: "Nunito Sans", sans-serif; */
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }

  ::selection {
    background-color: #ffe8b6;
    color: black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 10px;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none !important;
}

.rdrDateRangePickerWrapper,
.rdrCalendarWrapper {
  border-radius: 4px !important;
}

.PhoneInput {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

.PhoneInputCountry {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 20px; */
  width: 15%;
}

.PhoneInput input {
  height: 44px;
  outline: none;
  border: none;
  border-left: 1px solid #d0d5dd;
  padding: 10px;
  border-radius: 0 8px 8px 0px;
}

.timePickerStyles {
  z-index: 11111111;
}

.textarea {
  border: 1px solid transparent;
  outline: 1px solid transparent;
}
